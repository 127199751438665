import { AsyncPipe, NgClass } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { HeaderDetails, ProductHeaderComponent } from 'src/app/core/ui/product-header/product-header.component';
import { SvgImagesComponent } from "../../../../core/ui/svg-images/svg-images.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';
import { ReportingActions } from 'src/app/store/actions/reporting.actions';
import { TooltipDirective } from 'src/app/core/directives/tooltip.directive';
import { selectFavouriteReports } from 'src/app/store/selectors/reporting.selectors';

@Component({
  selector: 'app-product-dashboard-header',
  imports: [NgClass, AsyncPipe, SvgImagesComponent, TranslateModule, TooltipDirective],
  standalone: true,
  templateUrl: './product-dashboard-header.component.html',
  styles: `
    :host {
      display: block;
    }
  `
})
export class ProductDashboardHeaderComponent extends ProductHeaderComponent implements OnInit {

  protected dashboardHeaderData$: Observable<HeaderDetails & {isFavourite: boolean}>;
  private translateService = inject(TranslateService);

  ngOnInit(): void {
    super.ngOnInit();
    this.dashboardHeaderData$ = this.composeHeaderData$();
  }

  protected toggleReportBookmark(isFavourite: boolean, report: string): void {
    const label = this.translateService.instant(report)
    if(isFavourite) {
      this.store.dispatch(ReportingActions.deleteFavourite({ label }));
      return;
    }
    this.store.dispatch(ReportingActions.addFavourite({ label, value: { label } }));
  }

  private composeHeaderData$(): Observable<HeaderDetails & {isFavourite: boolean}> {
    return combineLatest([
      this.headerDetails$,
      this.store.select(selectFavouriteReports)
    ]).pipe(
      map(([headerDetails, favourites]) => ({ ...headerDetails, isFavourite: favourites.has(this.translateService.instant(headerDetails.title)) }))
    );
  }
}
