<div class="flex items-center justify-between pb-3 gap-4 text-sm">
    @if (dashboardHeaderData$ | async; as details) {
      <div class="flex items-center gap-3 flex-1">
        <div class="space-y-0 5">
            <div class="flex items-center gap-3">
                <h3 class="font-medium text-xl leading-none pr-3 font-title dark:text-white/80">
                    {{ details.title | translate }}
                </h3>
                <div class="flex items-center gap-2">
                    <!-- Pinning button -->
                    <button [appTooltip]="(details.isFavourite ? 'product.bookmark.unpin' : 'product.bookmark.pin') | translate" class="p-1.5 rounded hover:bg-white dark:hover:bg-slate-900 active"
                        (click)="toggleReportBookmark(details.isFavourite, details.title)">
                        <app-svg-images
                            class="h-4.5 text-amber-500 flex"
                            [svgName]="details.isFavourite ? 'bookmark-solid' : 'bookmark'"
                            [ngClass]="{ 'opacity-70 dark:text-amber-200': !details.isFavourite}">
                        </app-svg-images>
                    </button>
                    @if ((details.description | translate)?.length > 0) {
                        <!-- Info button -->
                        <button [appTooltip]="details.description | translate" tooltipPosition="right" class="p-1.5 rounded-md hover:shadow-sm hover:bg-soft-blue dark:hover:bg-slate-900 active">
                            <app-svg-images svgName="question-mark-circle" class="h-4.5 text-gray-400 dark:text-white/80 flex"></app-svg-images>
                        </button>
                    }
                </div>
            </div>
        </div>
      </div>
    }
    <div class="flex items-center gap-2 justify-end text-[13px]">
      <ng-content></ng-content>
    </div>
  </div>
